@import "../components/color.scss";
@import "../layout/customTable.scss";
.master-add {
  .dotButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    position: relative;

    .menu {
      .item {
        text-align: left;
        font: normal normal medium 14px/18px Quicksand;
        letter-spacing: 0px;
        color: #193256;
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        font-family: Quicksand;
      }
    }
  }
}

.add-role-permission-scope {
  background: #e9f7fa 0% 0% no-repeat padding-box;
  border: 1px solid #d1eeff;
  opacity: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.permission-master {
  min-width: 150px;
  .content {
    .header {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      gap: 5px;
      letter-spacing: 0px;
      color: #193256 !important;
      font-size: 14px !important;
      font-weight: 600;
      line-height: 18px;
      font-family: Quicksand !important;

      .ui.checkbox .box:before,
      .ui.checkbox label:before {
        background: transparent;
        border: 1px solid #f18a00;
      }

      .ui.checkbox input:checked ~ .box:after,
      .ui.checkbox input:checked ~ label:after {
        color: #f18a00;
      }
    }
  }
}

.permission-block {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  .accessSpinner {
    margin-top: 10vw;
    margin-left: 60vh;
  }
}

.master-dropdown-add {
  .item {
    font-family: Quicksand !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
.masterSidebar {
  overflow: auto;
  max-height: 100vh;
}
@include device-sm {
  .add-role-permission-scope {
    background: #e9f7fa 0% 0% no-repeat;
    border: 1px solid #d1eeff;
    opacity: 1;
    width: 50%;
    height: 100%;
    justify-content: left;
  }
}
.masterMain {
  margin-bottom: 0px !important;
  height: 100vh;
  overflow-y: auto;
}

.pointerDiv {
  width: 100% !important;
}
