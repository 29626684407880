@import "./base/mixins";
@import "./base/fonts";
@import "./pages/404";
@import "./pages/login";
@import "./pages/login_v2";
@import "./pages/jobDetails";
@import "./pages/master";
@import "./pages/addStaff";
@import "./pages/newClient";
@import "./pages/templates";
@import "./pages/candidate";
@import "./components/card";
@import "./components/leaveCard";
@import "./components/chart";
@import "./components/dateRange";
@import "./components/dotChart";
@import "./components/dotForm";
@import "./components/dotTable";
@import "./components/dotCalendar";
@import "./components/dotAttendance";
@import "./components/dotModal";
@import "./components/dotQuotation";
@import "./components/dotMarketing";
@import "./components/stepper";
@import "./components/reports";
@import "./layout/sidebar";
@import "./layout/customTable";
@import "./components/color";
@import "./layout/bubble";
@import "./layout/invoice";

body {
  margin: 0;
  font-family: Quicksand, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeaea;
}

// * {
//   font-family: Quicksand !important;
//   .icon{
//     font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
//   }
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fontQuicksand,
p {
  font-family: Quicksand !important;
  font-weight: medium !important;
}

.ui .fields .field label {
  font-family: Quicksand !important;
  font-weight: medium !important;
}

input,
button,
.sidebar {
  font-family: Quicksand !important;
  font-weight: medium !important;
}

.pusher-overflow {
  overflow: hidden !important;
  background-color: #f3f7f9 !important;
}

.pointer {
  cursor: pointer;
}
.text_link{
color: #0000EE;
text-decoration: underline;

}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.items-center {
  align-items: center !important;
}
.text-center {
  text-align: center;
}
.w-50 {
  width: 50%;
}
.h-100 {
  height: 100% !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mt-100 {
  margin-top: 100% !important;
}
.weight-bold {
  font-weight: bold;
}
.p-2 {
  padding: 2rem !important;
}

.file-uploads {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  & .item {
    padding: 10px;
    margin: 10px;
    border: 1px solid rgba(black, 0.2);
    border-radius: 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      padding-right: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.main-title {
  color: #193256;
  font-size: 1.5em;
  font-weight: bold;
}
.main-sub-title {
  color: #193256;
  font-size: 1em;
  font-weight: bold;
}

.dotkris-checkbox {
  & label:before {
    border-width: 2px !important;
    width: 20px !important;
    height: 20px !important;
    border-color: $dotOrrange !important;
  }

  & input:checked ~ label:after {
    color: $dotWhite !important;
    background-color: $dotOrrange !important;
    width: 20px !important;
    height: 20px !important;
  }
}

.main-height {
  height: 100vh;
  overflow: auto;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.textarea-no-resize {
  textarea {
    resize: none !important;
  }
}

.upload-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  & .item {
    padding: 10px;
    margin: 10px;
    border: 1px solid rgba(black, 0.2);
    border-radius: 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      padding-right: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.avatar-box {
  height: 40px;
  width: 40px;
  background: #ffeddb 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: left;
  font: normal normal medium 16px/25px Quicksand;
  font-size: 16px;
  line-height: 25px;
  font-weight: 600;
  font-family: Quicksand;

  letter-spacing: 0px;
  color: #ff8d1e;
  opacity: 1;
}
.main-bx-shadow {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.user-btn {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0;

  &:active,
  &:hover,
  &:focus {
    background-color: transparent !important;
  }

  span {
    text-align: left;
    font: normal normal bold 16px/18px Quicksand;
    letter-spacing: 0px;
    color: $dotWhite;
    opacity: 1;
  }
}

.main-container {
  overflow: hidden !important;
}

@include device-sm {
  .ui.main-container {
    width: auto !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}

.w-100 {
  width: 100% !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-5 {
  padding: 5rem !important;
}
.ui.inverted.menu .active.side-bar-menu {
  background-color: $dotOrrange !important;
}

.ui.labeled.icon.menu .logo {
  height: 105px !important;

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}

.ui.inverted.menu .active.logo {
  background-color: transparent !important;
}

.ui.inline.nav-bar-dropdown {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: $dotWhite !important;
}

.ui.vertical.icon.menu .side-bar-menu > .icon:not(.dropdown),
.ui.labeled.icon.menu .icon > .icon:not(.dropdown) {
  display: block;
  opacity: 1;
  /* float: none; */
  margin: 0 !important;
  margin-right: 1rem !important;
}

.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  height: 1em;
  display: block;
  font-size: 1em !important;
  margin: 0 0 !important;
  margin-right: 10px !important;
}

.ui.modal > .close {
  cursor: pointer;
  position: absolute;
  top: 0.5rem !important;
  right: 0.5rem !important;
  z-index: 1;
  opacity: 0.8;
  font-size: 1.25em;
  color: grey;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.625rem 0 0 0;
}

.ui.modal {
  border-radius: 10px !important;
  overflow: hidden;
}

.btn-sidebar {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
}

.user-menu {
  border: none !important;
  padding: 0px !important;
  // padding-bottom: 1px !important;
}

.user-avatar {
  background-color: grey !important;
  border: none !important;
  outline: none !important;
  height: 2.3em !important;
  width: 2.3em !important;
  border-radius: 50% !important;
}

.user-dropdown-popup {
  padding: 1.033em 0em !important;
  min-width: 10em !important;
}

.user-menu .item {
  &:hover {
    background-color: $dotNavyBlue !important;
    color: #eaeaea !important;

    transition: all 300ms ease-in;
  }

  &:first-child {
    border-radius: 0 !important;
  }

  &:last-child {
    border-radius: 0 !important;
    margin-bottom: 4px;
  }
}

.ui.pagination.menu {
  &:not(:first-child),
  :not(:last-child) {
    gap: 10px;
  }

  &:last-child::after {
    content: none !important;
  }

  background: transparent !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  .item {
    outline: none;
    border-radius: 10px !important;
    font-weight: 600 !important;
    background-color: #ffffff !important;

    &:focus {
      outline: none;
    }

    &:first-child,
    &:last-child {
      background-color: transparent !important;

      &:focus,
      &:hover,
      &:active {
        background-color: transparent !important;
        color: $dotNavyBlue !important;
      }
    }

    &:focus,
    &:hover,
    &:active,
    &.active {
      background-color: $dotNavyBlue !important;
      color: #ffffff !important;
    }
  }
}

.container-height-width {
  width: calc(100%);
  // height: calc(100vh - 150px);
}

.top-margin-20 {
  margin-top: 20px;
}

.bottom-margin-50 {
  margin-bottom: 50px;
}

.app-height {
  height: calc(100vh - 48px);
  overflow-y: auto;

  // padding: 1em;
  @include device-md {
    &.ui.container {
      width: auto !important;
      margin-left: 0em !important;
      margin-right: 0em !important;
    }
  }
}

.container-height-width {
  padding: 0rem;

  // margin: 1rem !important;
  @include device-md-min {
    padding: 1.5rem;
  }

  @include device-md {
    // margin-top: 0.5em !important;
    // margin-left: 0.5em !important;
    // margin-right: 0.5em !important;
  }
}

.table-with-drop-down {
  background: $dotNavyBlue !important;
  color: $dotWhite !important;
  border-left: 0.5px solid $dotWhite;
  border-right: 0.5px solid $dotWhite;
  border-bottom: 0.5px solid $dotDarkGrey;
}

.calendar {
  color: $dotOrrange !important;
}

.master-dropdown-popup {
  padding: 1.033em 0em !important;
  min-width: 100% !important;
}

.ui.modal > .header {
  border-bottom: transparent !important;
  text-align: left;
  font: normal normal bold 20px/25px Quicksand;
  letter-spacing: 0px;
  color: $dotNavyBlue;
  opacity: 1;
  font-size: 24px !important;
  line-height: 25px !important;
  font-weight: 800 !important;
  font-family: Quicksand !important;
  padding-bottom: 6px;
}
// .header-fix {
//   padding-bottom: 0px;
// }
.ui.modal > .content {
  width: auto !important;
  margin-left: 0px !important;
}

.add {
  .ui.input > input {
    width: 100% !important;
  }

  .dropdown {
    width: 100%;
    padding-left: 14px !important;
  }
}

.staffTable {
  background: $dotNavyBlue !important;
  color: $dotWhite !important;
  border-left: 0.5px solid $dotWhite;
  border-right: 0.5px solid $dotWhite;
  border-bottom: 0.5px solid $dotDarkGrey;
  font-family: Quicksand !important;
  font-weight: medium !important;
  font-size: 14px !important;
}

.checkboxTablehead label:before {
  background-color: $dotNavyBlue !important;
  border-width: 2px !important;
  width: 20px !important;
  height: 20px !important;
  border-color: $dotWhite !important;
}

.checkboxTablehead {
  margin-right: 20px !important;
}

.checkboxTablehead input:checked ~ label:after {
  color: $dotWhite !important;
  border-color: $dotWhite !important;
  width: 20px !important;
  height: 20px !important;
}

.checkboxTablerow label:before {
  // background-color: $dotOrrange !important;
  border-width: 2px !important;
  width: 20px !important;
  height: 20px !important;
  border-color: $dotOrrange !important;
}

.checkboxTablerow input:checked ~ label:after {
  color: $dotWhite !important;
  background-color: $dotOrrange !important;
  width: 20px !important;
  height: 20px !important;
}

.checkboxTablerow {
  margin-right: 20px !important;
}

.calenderDiv {
  float: left;
  margin-left: 4%;
  margin-top: 1%;
}
.ui.table thead th {
  &.table-with-no-border {
    border: none !important;
    &.table-word-break {
      font-size: 0.66vw;
      // white-space: inherit;
    }
  }
}

.tableStyle {
  z-index: auto;
}

.mb-5 {
  margin-bottom: 5%;
}

.q-sand {
  font-family: Quicksand !important;
  font-weight: 500;
  font-size: 100%;
}

.dateRange {
  div {
    margin-right: 16px;
    font-family: QuicksandMedium !important;
    font-weight: 500;
    color: $dotNavyBlue;
  }

  display: flex;
  align-items: center;
}

.check-in-out {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.my-swipes {
  text-align: left;
  text-decoration: underline;
  // font: normal normal medium 12px/18px Poppins;
  font-family: Quicksand;
  font-weight: 600;
  font-size: 14px;
  font-weight: 18px;
  letter-spacing: 0px;
  color: #f18a00;
  opacity: 1;
  cursor: pointer;
}

.bubbles {
  min-height: 250px;
  min-width: 250px;
  opacity: 0.1;
  position: absolute;
  background: #ffffff;
  display: block;
  border-radius: 50%;
}

.bubblesTest {
  min-height: 250px;
  min-width: 250px;
  opacity: 0.1;
  position: absolute;
  background: #ffffff;
  display: block;
  border-radius: 50%;
}

.mySwipesFont {
  text-align: left;
  font: normal normal medium 14px/18px Quicksand;
  letter-spacing: 0px;
  color: #193256;
  opacity: 1;

  font-family: Quicksand !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 800 !important;
}

.textSwipe {
  text-align: left;
  font: normal normal medium 14px/18px Quicksand;
  letter-spacing: 0px;
  color: #f18a00 !important;
  opacity: 1;

  font-family: Quicksand !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 800 !important;
}

.ui.form {
  .fields .disabled {
    opacity: 1 !important;

    label {
      opacity: 1 !important;
    }
  }
}

.ui.pagination.right.floated.menu {
  .ui.menu.item {
    padding: 0.92857143em 1.042857em;
  }
}

// .ui.form .field.disabled>label, .ui.form .fields.disabled>label {
//   opacity: 1 !important;
// }

.data-card-file {
  height: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.data-card-name {
  font-size: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-overflow: ellipsis;
}

.checkboxJob {
  // background-color: $dotNavyBlue !important;
  border-width: 2px !important;
  // width: 25px !important;
  height: 20px !important;
  border-color: $dotWhite !important;
}

.checkboxStaff {
  // background-color: $dotNavyBlue !important;
  border-width: 2px !important;
  // width: 25px !important;
  color: #f18a00 !important;
  margin-top: 25px !important;
  // height: 20px !important;
  border-color: $dotWhite !important;
}

.genderLabel {
  margin-top: 25px !important;
  text-align: left !important;
  display: block;
  padding-bottom: 15px;
}

.small-dropdown .ui.search.dropdown .menu {
  max-height: 9.028571rem;
}

.AllNotificationDeleteColor {
  color: $dotOrrange !important;
}
.w60 {
  width: 70px !important;
}

.treemap-graph {
  span.small-box {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
  }

  .fontQuicksand {
    font-size: 1rem;
    color: #193256;
  }

  .green {
    background: green;
  }

  .red {
    background: red;
  }

  .black {
    background: black;
  }

  .d-flex {
    display: flex;
    justify-content: space-evenly;
  }
}

.mainDiv .custom-table {
  &.TimevsActualBill,
  &.ProductivityMark,
  &.TimeKeeping {
    height: auto;
    min-height: 300px;
    // display: table-caption;
  }
}

.genderInput {
  .dropdown {
    > div,
    .visible .item {
      text-transform: capitalize;
    }
  }
}
