@import "./color.scss";

.RichEditor-root,
.root-editor {
  background: #fff;
  border: 1px solid #ddd;
  // font-family: "Georgia", serif;

  padding: 15px;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  // font: normal normal normal 14px/21px Poppins;
  font-family: Quicksand;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 10px;

  & .top-label {
    display: inline-block;
    padding: 5px 10px;
    background-color: #fff;
    position: absolute;
    transform: translateY(-100%);
    font-size: 80%;
    font-weight: bold;
    text-transform: uppercase;
    color: $dotOrrange;
  }

  &.error {
    background: #fff6f6 !important;
    border-color: #e0b4b4 !important;
  }
}

.RichEditor-editor {
  border-bottom: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  min-height: 100px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  font-family: Quicksand;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.controller {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  min-height: 50px;
  align-items: center;
  padding-top: 10px;
  // background-color: #E1E7FA;
  // padding: 0px 0px 0px 14px;
}

.controllerFlex {
  display: flex;
  justify-content: flex-start;
}

.controllerButtonFlex {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  // button:first-of-type{
  //   min-width: 40px;
  //   border: none;
  //   width: 16px;
  // }
}

.font-quicksand {
  font-family: Quicksand !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  // line-height: 20px !important;
}
