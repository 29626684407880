@import "../components/color.scss";
.login-intro-animation {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  background: rgb(48, 105, 170);
  background: linear-gradient(
    180deg,
    rgba(48, 105, 170, 1) 0%,
    rgba(101, 227, 241, 1) 100%
  );
  position: relative;

  .login-intro-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    position: absolute;
    top: 20%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.login-intro-animation-header {
  // font-size: 150px;
  // line-height: 180px;
  font-family: Quicksand;
  font-weight: 700;
  text-align: center;
  color: white;
}

.login-intro-animation-dots {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  #cir1 {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: linear-gradient(
      0deg,
      rgba(131, 58, 180, 1) 0%,
      rgba(253, 29, 29, 1) 50%,
      rgba(252, 176, 69, 1) 100%
    );
  }

  #cir2 {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(122, 201, 144, 1) 100%
    );
  }

  #cir3 {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(92, 140, 201, 1) 100%
    );
    overflow: hidden;
  }

  #cir4 {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: linear-gradient(
      308deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(126, 6, 168, 1) 100%
    );
  }

  #cir5 {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(34, 193, 195, 1) 0%,
      rgba(126, 6, 168, 1) 100%
    );
  }
}

.logo-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;

  bottom: 6%;
  left: 30%;
  opacity: 0;
  height: 50%;
  width: 20vw;
}

.logo {
  color: white !important;
}

.login-type {
  display: flex;
  flex-direction: column;
  background-blend-mode: darken;
  float: right;
  opacity: 0.99;
  position: relative;
}

.input-box {
  border-radius: 50%;
  padding: 10px;
  background-image: linear-gradient(#f18a00, #f18a00, #f18a00);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0;
  transform: scale(0);
}

.input-toggler {
  border-radius: 50%;
  // padding: 10px;
  background-image: linear-gradient(#f18a00, #f18a00, #f18a00);
  margin-left: auto;
  cursor: pointer;
  font-size: 1em;
  // line-height: 14px;
  font-family: Quicksand;
  font-weight: normal;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  opacity: 0;
  position: absolute;
  bottom: 2%;
  width: 7em;
  height: 7em;
  text-align: center;

  padding: 2%;

  ::selection {
    background-color: transparent;
    color: #ffffff;
  }
}
.input-toggler-blue {
  border-radius: 50%;
  // padding: 10px;
  background-image: linear-gradient(#f18a00, #f18a00, #f18a00);
  margin-left: auto;
  cursor: pointer;
  font-size: 1em;
  // line-height: 14px;
  font-family: Quicksand;
  font-weight: 600;
  color: #193256;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  opacity: 0;
  position: absolute;
  bottom: 2%;
  width: 7em;
  height: 7em;
  text-align: center;

  padding: 2%;

  ::selection {
    background-color: transparent;
    color: #ffffff;
  }
}

.forgot-section {
  border-radius: 50%;
  // padding: 10px;
  background: #193256 0% 0% no-repeat padding-box;
  margin-left: auto;
  cursor: pointer;
  font-size: 10px;
  line-height: 14px;
  font-family: Quicksand;
  font-weight: normal;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  opacity: 0;
  position: absolute;
  bottom: 2%;
  width: 70px;
  height: 70px;
  text-align: center;
  padding: 2%;

  ::selection {
    background-color: transparent;
    color: #ffffff;
  }
}

.login-type-submit {
  cursor: pointer;
  background-image: linear-gradient(#193256, #193256, #193256);
  border: none !important;
  opacity: 1;
  color: white;
  font-size: 0.7em;
  line-height: 23px;
  font-family: Quicksand;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  outline: none;

  position: absolute;
  bottom: 2%;

  &:active,
  &:focus {
    outline: none;
  }
}
.loginBottom8 {
  bottom: 8% !important;
}
.login-input-header {
  text-align: center;
  font: normal normal bold 2em/30px Quicksand;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 500;
}
.login-input-header-keyword {
  margin: 0 0 0.5em !important;
  text-align: center;
  font: normal normal bold 2em/30px Quicksand;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 500;
}
.login-input-header-blue {
  text-align: center;
  font: normal normal bold 2em/30px Quicksand;
  letter-spacing: 0px;
  color: #193256;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 500;
}
.centerFlex {
  align-items: center !important;
}
.keywordMainBody{
  margin-left: 6% !important;
}
.input-form-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  width: 75%;

  .input-form-control-label {
    text-align: left;
    font: normal normal bold 100%/18px Quicksand !important;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;

    &-forgot-password {
      text-align: right !important;
      font: normal normal bold 10px/18px Quicksand !important;
      color: #ffffff !important;
      margin-left: auto !important;
      cursor: pointer !important;

      label {
        margin: 0 !important;
      }
      // @media only screen and (min-width: 1400px){
      //     /*Tablets [601px -> 1200px]*/
      //     font: normal normal bold 1em/18px Quicksand !important;

      // }
      @include device-xl-min {
        font: normal normal bold 1em/18px Quicksand !important;
      }
    }

    @include device-xl-min {
      font: normal normal bold 1em/18px Quicksand;
    }
  }

  .input-form-control-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #193256;
    border-radius: 27px;
    opacity: 1;
    height: 40px;
    width: 100%;
    padding: 10px 10px;
    outline: none;
    font: normal normal bold 10px/18px Quicksand;

    // @media only screen and (min-width: 1400px){
    //     /*Tablets [601px -> 1200px]*/
    //     font: normal normal bold 1em/18px Quicksand;

    // }
    @include device-xl-min {
      font: normal normal bold 1em/18px Quicksand;
    }
  }
}
.input-form-control-blue {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  width: 75%;

  .input-form-control-label {
    text-align: left;
    font: normal normal bold 100%/18px Quicksand !important;
    letter-spacing: 0px;
    color: #193256;
    opacity: 1;

    &-forgot-password {
      text-align: right !important;
      font: normal normal bold 10px/18px Quicksand !important;
      color: #193256 !important;
      margin-left: auto !important;
      cursor: pointer !important;

      label {
        margin: 0 !important;
      }
      // @media only screen and (min-width: 1400px){
      //     /*Tablets [601px -> 1200px]*/
      //     font: normal normal bold 1em/18px Quicksand !important;

      // }
      @include device-xl-min {
        font: normal normal bold 1em/18px Quicksand !important;
      }
    }

    @include device-xl-min {
      font: normal normal bold 1em/18px Quicksand;
    }
  }

  .input-form-control-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #193256;
    border-radius: 27px;
    opacity: 1;
    height: 40px;
    width: 100%;
    padding: 10px 10px;
    outline: none;
    font: normal normal bold 10px/18px Quicksand;

    // @media only screen and (min-width: 1400px){
    //     /*Tablets [601px -> 1200px]*/
    //     font: normal normal bold 1em/18px Quicksand;

    // }
    @include device-xl-min {
      font: normal normal bold 1em/18px Quicksand;
    }
  }
}

.errorLabel {
  text-align: center;
  font: normal normal bold 0.8em/10px Quicksand;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
  // @media only screen and (min-width: 1400px){
  //     /*Tablets [601px -> 1200px]*/
  //     font: normal normal bold 1em/10px Quicksand;

  // }
  @include device-xl-min {
    font: normal normal bold 1em/18px Quicksand;
  }
}

.successLabel {
  margin-left: 6%;
  text-align: right;
  font: normal normal bold 0.8em/10px Quicksand;
  letter-spacing: 0px;
  color: green;
  opacity: 1;
  @include device-xl-min {
    font: normal normal bold 1em/18px Quicksand;
  }
}

.login-container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: rgb(48, 105, 170);
  background: linear-gradient(
    180deg,
    rgba(48, 105, 170, 1) 0%,
    rgba(101, 227, 241, 1) 100%
  );
  position: relative;

  .login-main-intro-logo {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    position: absolute;
    top: 20%;
    opacity: 0;
    transform: translateX(-110%);
  }

  .login-main-intro-background {
    opacity: 1;
    background: #193256;
    position: absolute;
    z-index: 8;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    max-width: 100%;
    clip-path: circle(100% at 0 50%);
    transform: scale(0);
    transform: translateX(-110%);
    opacity: 1;
  }

  .login-intro-animation-header {
    // font-size: 150px;
    // line-height: 180px;
    font-family: Quicksand;
    font-weight: 700;
    text-align: center;
    color: white;
    // height: 200px;
    // width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-intro-animation-header-img {
      height: 17vh;
      width: 15vw;
    }
  }

  .login-intro-animation-dots {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @mixin login {
      width: 27px;
      height: 27px;
    }

    #cir1 {
      @include login;
      border-radius: 50%;
      background: transparent
        radial-gradient(closest-side at 68% 24%, #7ef1ff 0%, #65c4d9 100%) 0% 0%
        no-repeat padding-box;
      // background: linear-gradient(0deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    }

    #cir2 {
      @include login;
      border-radius: 50%;
      background: transparent
        radial-gradient(closest-side at 68% 24%, #ffc8e9 0%, #e31d93 100%) 0% 0%
        no-repeat padding-box;
      // background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(122, 201, 144, 1) 100%);
    }

    #cir3 {
      @include login;
      border-radius: 50%;
      background: transparent
        radial-gradient(closest-side at 60% 29%, #d4ffa1 0%, #76bc21 100%) 0% 0%
        no-repeat padding-box;
      // background: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(92, 140, 201, 1) 100%);
      overflow: hidden;
    }

    #cir4 {
      position: relative;
      @include login;
      border-radius: 50%;
      background: transparent
        radial-gradient(closest-side at 68% 24%, #ffdf67 0%, #e18100 100%) 0% 0%
        no-repeat padding-box;
      // background: linear-gradient(308deg, rgba(34, 193, 195, 1) 0%, rgba(126, 6, 168, 1) 100%);
    }

    #cir5 {
      @include login;
      border-radius: 50%;
      background: transparent
        radial-gradient(closest-side at 68% 24%, #03fff6 0%, #00bbb4 100%) 0% 0%
        no-repeat padding-box;
      // background: radial-gradient(circle, rgba(34, 193, 195, 1) 0%, rgba(126, 6, 168, 1) 100%);
    }
  }
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.client-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.team-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  // display: none;
  opacity: 0;
  // transform: scale(0);
  // transform: translateX("110%");
}

.forgot-password-description {
  text-align: center;
  color: white;
  font-family: "QUICKSAND";
  font-size: 12px;
  padding: 0 12%;
  line-height: 12px;
}

.forgot-password-description-verify {
  text-align: center;
  color: white;
  font-family: "QUICKSAND";
  font-size: 14px;
  padding: 0 8%;
  font-weight: bold;
  line-height: 18px;
  font-weight: 700;
}
.-blue {
  color: $dotNavyBlue;
}

//@at-rootOTP

.otp-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  margin: 0 auto;

  input {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 5px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 50%;
    overflow: hidden;

    &:last-child {
      margin-right: 0;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    &:focus,
    &.focus {
      border-color: green;
      outline: none;
      box-shadow: none;
    }
  }
}

.inputStyle {
  width: 2rem !important;
  height: 2rem;
  margin: 0px 0.3rem;
  font-size: 0.9rem;
  border-radius: 4px;
  border: 1px solid #193256;
  border-radius: 50%;
  outline: none;
  font-family: Quicksand;
  color: #193256;
}
.inputStyleCus {
  width: 3.5rem !important;
  height: 3.5rem;
  margin: 0px 0.3rem;
  font-size: 1.2rem;
  border-radius: 4px;
  border: 1px solid #193256;
  border-radius: 50%;
  outline: none;
  font-family: Quicksand;
  color: #193256;
}

.reset-password-controller {
  width: 75%;
}

.reset-password-response-controller {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadow {
  width: 60px;
  height: 30px;
  background: radial-gradient(
    ellipse at center,
    rgba(88, 24, 69, 1) 0%,
    rgba(237, 237, 237, 0) 50%
  );
  position: relative;
  z-index: -1;
  transform: translateY(75px);
  opacity: 0.1;
}

.circle-3rem {
  width: 2.2rem !important;
  height: 2.2rem;
}
.setKeywordMessage {
  margin: 0px 24px 0px 10px !important;
  color: $dotNavyBlue;
  font-weight: 600;
  font-size: 11px;
}
