@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: "QuicksandBold";
  src: url("./static/QuicksandBold.ttf") format("truetype");
}

@font-face {
  font-family: "QuicksandLight";
  src: url("./static/QuicksandLight.ttf") format("truetype");
}

@font-face {
  font-family: "QuicksandMedium";
  src: url("./static/QuicksandMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: url("./static/QuicksandRegular.ttf") format("truetype");
}

@font-face {
  font-family: "QuicksandSemiBold";
  src: url("./static/QuicksandSemiBold.ttf") format("truetype");

}
