@import "../components/color";

.Candidate {
  &__profile {
    padding: 1rem;
    &-header {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      & .header {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        & .avatar-box {
          margin-right: 1rem;
        }
        & .info {
          & .name {
            color: #193256;
            font-weight: bold;
            font: normal normal bold 20px/25px Quicksand;
            margin-bottom: 0.2em;
          }
          & .timestamp {
            font-size: 1rem;
            color: #9b9b9b;
          }
        }
      }
      & .cta {
        background: #f18a00 0% 0% no-repeat padding-box;
        border-radius: 20px;
        & span {
          display: inline-block;
          color: white;
          padding: 12px 15px;
          font-weight: bold;
        }
      }
    }
    &-description {
      margin: 2rem 0;
      & .keyvaluebox {
        margin: 1rem 0;
        & .key,
        & .value {
          font-size: 1.2rem;
          color: #193256;
        }
        & .key {
          font-weight: bold;
        }
      }
      & .work-experience {
        & .header {
          padding: 1rem 0 !important;
        }
      }
    }
    &-resume {
      & button {
        padding: 2rem !important;
        background: #65c4d9 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #65c4d91f !important;
        border-radius: 0px 12px 12px 12px !important;
        color: white !important;
        font-size: 1.2rem !important;
        & span {
          margin-right: 1rem;
        }
      }
    }
    &-action {
      margin: 2rem 0;
      & .key {
        font-size: 1.2rem;
        color: #193256;
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }
  &__timeline {
    max-height: 50vh !important;
    overflow: auto;
    &-header {
      text-align: left;
      font: normal normal medium 16px/17px Quicksand;
      font-size: 18px;
      font-weight: 600;
      font-family: Quicksand;
      letter-spacing: 0px;
      color: #f18a00;
      opacity: 1;
      margin-bottom: 1rem;
    }
    &-container {
      width: 100%;
      & .title {
        text-align: left;
        font: normal normal medium 14px/18px Quicksand;
        font-size: 16px;
        font-weight: 600;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #193256;
        opacity: 1;
      }
      & .logo-box {
        max-height: 50vh;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
          height: 10px;
        }
      }
    }
  }
  &__submissions {
    padding: 1rem;
    &-description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 1.5rem !important;
      position: relative !important;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 10px 10px 0 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.5rem;
        background-color: $dotOrrange;
      }
      & .info {
        display: flex;
        flex-direction: column;
        & .title {
          font: normal normal medium 23px/20px Quicksand;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 1rem;
        }
        & .description {
          font: normal normal normal 16px/20px Quicksand;
          letter-spacing: 0px;
          color: #818181;
        }
      }
      & .cta {
        display: inline-flex;
        align-items: center;
        & .title {
          font: normal normal bold 20px/17px Quicksand;
          letter-spacing: 0px;
          color: #65c4d9;
          margin-right: 10px;
        }
        & .score {
          display: block;
          background: #65c4d9 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #65c4d91f;
          border-radius: 8px;
          padding: 2rem;
          color: white;
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }
    &-question {
      padding: 2rem 1.5rem !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 10px 0 0 10px;
        height: 100%;
        width: 0.5rem;
        background-color: $dotOrrange;
        left: 0;
        top: 0;
        bottom: 0;
      }
      & .question-container {
        width: 70%;
        margin-right: 2rem;
        & .question {
          background: #f0f0f0 0% 0% no-repeat padding-box;
          padding: 1rem;
          border-radius: 5px;
          font-size: 1.2rem;
          font-weight: bold;
          color: #000000;
          margin-bottom: 1.5rem;
          width: max-content;
        }
        & .options {
          display: flex;
          flex-direction: column;
          & div {
            font-size: 1.1rem;
            padding: 1rem;
            border-radius: 10px;
            font-weight: 500;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
      & .answer-container {
        width: 30%;
        text-align: center;
        margin: auto;
        font-size: 1.3rem;
        font-weight: bold;
        & .icon {
          box-shadow: none !important;
        }
      }
      & .correct {
        color: #0b931e;
      }
      & .incorrect {
        color: #f12626;
      }
    }
  }

  & .b-dotted-r {
    border-right: 2px dotted #7d9eb5;
  }
  & .b-dotted-b {
    border-bottom: 2px dotted #7d9eb5;
  }
  & .grid-box {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    & .column {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.nameDropdown{
  margin-top: 8px !important;
    margin-left: -10px !important;
}

